//
// topbar.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  span.logo-lg {
      display: block;
  }
  span.logo-sm {
      display: none;
  }

  &.logo-dark{
      display: block;
  }

  &.logo-light{
      display: none;
  }
}

.logo-box {
  height: $topbar-height;
  width: $leftbar-width;
  float: left;
  background-color: $logo-box-background;
  transition: all .2s ease-out;
}

.navbar-custom {
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(58, 83, 121, 0.10);
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
  position: fixed;
  left: 0;
  right: 0;
  height: $topbar-height;
  z-index: 100;

  .topnav-menu {
      &.topnav-menu-left{
          margin-left: 6px;
          display: inline-block;
      }
      > li {
          float: left;
      }
      .nav-link {
          padding: 0 15px;
          color: $header-item-color;
          min-width: 32px;
          display: block;
          line-height: $topbar-height;
          text-align: center;
          max-height: $topbar-height;
      }
  }
  .dropdown.show {
      .nav-link {
          background-color: rgba($gray-700,0.05);
      }
  }

  /* Search */
  .app-search {
      overflow: hidden;
      height: $topbar-height;
      display: table;
      max-width: 180px;
      margin-right: 20px;

      .app-search-box {
          display: table-cell;
          vertical-align: middle;

          input::-webkit-input-placeholder {
              font-size: 0.8125rem;
              color: $gray-600;
          }
      }
      .form-control {
          border: none;
          height: 38px;
          padding-left: 20px;
          padding-right: 0;
          color: $dark;
          background-color: $topbar-search-bg;
          box-shadow: none;
          border-radius: 30px 0 0 30px;
      }
      .input-group-append {
          margin-left: 0;
          z-index: 4;
      }

      .btn {
          background-color: $topbar-search-bg;
          border-color: transparent;
          color: $header-item-color;
          border-radius: 0 30px 30px 0;
          box-shadow: none !important;
      }
  }

  .button-menu-mobile {
      border: none;
      color: $dark;
      display: inline-block;
      height: $topbar-height;
      line-height: $topbar-height;
      width: 60px;
      background-color: transparent;
      font-size: 24px;
      cursor: pointer;
  }

  .button-menu-mobile.disable-btn {
      display: none;
  }
}


/* Notification */
.noti-scroll {
  max-height: 230px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
      background-color: transparent;
      padding: 15px 20px;
  }

  .noti-icon {
      font-size: 21px;
      vertical-align: middle;
  }

  .noti-icon-badge {
      display: inline-block;
      position: absolute;
      top: 16px;
      right: 10px;
  }

  .notify-item {
      padding: 12px 20px;

      .notify-icon {
          float: left;
          height: 36px;
          width: 36px;
          font-size: 18px;
          line-height: 36px;
          text-align: center;
          margin-right: 10px;
          border-radius: 50%;
          color: $white;
      }

      .notify-details {
          margin-bottom: 5px;
          overflow: hidden;
          margin-left: 45px;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $gray-800;

          b {
              font-weight: $font-weight-semibold;
          }
          small {
              display: block;
          }
          span {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 13px;
          }
      }

      .user-msg {
          margin-left: 45px;
          white-space: normal;
          line-height: 16px;
      }
  }
  .profile-dropdown {
      .notify-item {
          padding: 7px 20px;
      }
  }
}

.profile-dropdown {
  width: 225px;
  i {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
  }
}

.nav-user {
  padding: 0 12px !important;
  img {
      height: 32px;
      width: 32px;
  }
}

.page-title-main {
  margin: 0;
  line-height: $topbar-height;
  padding: 0 20px;
}


@media (max-width: 768px) {
  .button-menu-mobile.disable-btn {
      display: block !important;
  }
  .page-title-main{
      display: none;
  }
}


// Topbar Dark
body[data-topbar="dark"]{
  .logo-box{
      background-color: $bg-topbar-dark;
  }
  .logo{
      &.logo-dark{
          display: none !important;
      }
      &.logo-light{
          display: block !important;
      }
  }

  .navbar-custom {
      background-color: $bg-topbar-dark;

      .topnav-menu {
          .nav-link {
              color: $header-dark-item-color;
          }
      }
      .dropdown.show {
          .nav-link {
              background-color: rgba($white,0.03);
          }
      }

      .button-menu-mobile {
          color: $white;
      }

      .page-title-main{
          color: $header-dark-item-color;
      }

      .waves-effect {
          .waves-ripple{
              background: rgba($white, 0.4);
          }
      }

      /* app Search */
      .app-search {
          input::-webkit-input-placeholder {
              color: rgba($white,0.7) !important;
          }
          .form-control {
              color: $white;
              background-color: lighten($bg-topbar-dark, 4%);
              border-color: lighten($bg-topbar-dark, 4%);
          }
          .btn {
              background-color: lighten($bg-topbar-dark, 4%);
              color: rgba($white,0.7);
          }
      }

  }

  .content-page{
      margin-top: calc(#{$topbar-height} + #{$grid-gutter-width});
  }
}



