//
// menu.scss
//

// Metis Menu Overwrite

.metismenu {
    padding: 0;

    li {
        list-style: none;
    }

    ul {
        padding: 0;

        li {
            width: 100%;
        }
    }

    .mm-collapse:not(.mm-show) {
        display: none;
    }

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }
}

.nav-second-level,
.nav-thrid-level {
    li {
        a {
            padding: 8px 20px;
            color: $sidemenu-item-color;
            display: block;
            position: relative;
            transition: all 0.4s;

            &:focus,
            &:hover {
                color: $sidemenu-item-hover-color;
            }
        }
    }
}

.nav-second-level,
.nav-third-level {
    li.mm-active {
        >a {
            color: $sidemenu-item-active-color;
        }
    }
}

// Wrapper
#wrapper {
    height: 100%;
    overflow: visible;
    width: 100%;
}

//Content Page
.content-page {
    margin-left: $leftbar-width;
    overflow: visible;
    height: fit-content;
    padding: 0 12px 2px 12px;
    min-height: 80vh;
    margin-top: $topbar-height;
}

.app {
    display: flex;
    overflow: visible;
}

// Sidemenu
.left-side-menu {
    width: $leftbar-width;
    background: $bg-leftbar-light;
    bottom: 0;
    padding: 20px 0;
    position: fixed;
    transition: all .2s ease-out;
    top: $topbar-height;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    z-index: 99;

    .user-box {
        .img-thumbnail {
            background-color: $bg-leftbar-light;
            border-color: darken($bg-leftbar-light, 4%);
        }
    }
}

// Sidebar
#sidebar-menu {
    >ul {
        >li {
            >a {
                color: $sidemenu-item-color;
                display: block;
                padding: 11px 20px;
                position: relative;
                transition: all 0.4s;
                font-size: 0.925rem;

                &:hover,
                &:focus,
                &:active {
                    color: $sidemenu-item-hover-color;
                    text-decoration: none;
                }

                >span {
                    vertical-align: middle;
                }

                i {
                    display: inline-block;
                    line-height: 1.0625rem;
                    margin: 0 10px 0 3px;
                    text-align: center;
                    vertical-align: middle;
                    width: 20px;
                }

                .drop-arrow {
                    float: right;

                    i {
                        margin-right: 0;
                    }
                }
            }

            >a.active {
                color: $sidemenu-item-active-color;
            }

            >ul {
                padding-left: 37px;

                ul {
                    padding-left: 20px;
                }
            }
        }
    }

    .submenu-arrow,
    .menu-arrow {
        transition: transform .15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: 'Material Design Icons';
        text-rendering: auto;
        line-height: 1.5rem;
        font-size: 1.1rem;
        transform: translate(0, 0);
        transform: rotate(180deg);

        &:before {
            content: "\F141";
        }
    }

    .badge {
        margin-right: 22px;
        margin-top: 5px;
    }

    li.mm-active {
        >a {

            >span.menu-arrow,
            span.submenu-arrow,
            {
            transform: rotate(270deg);
        }
    }
}


.menu-title {
    padding: 10px 20px;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: $gray-500;
    font-weight: $font-weight-medium;
}
}

// Enlarge menu
.enlarged {

    .logo-box {
        width: $leftbar-width-collapsed  !important;
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: block;
        }
    }

    // Side menu
    .left-side-menu {
        position: absolute;
        padding-top: 0;
        width: $leftbar-width-collapsed  !important;
        z-index: 9;

        .slimScrollDiv,
        .slimscroll-menu {
            overflow: inherit !important;
            height: auto !important;
        }

        .slimScrollBar {
            visibility: hidden;
        }

        // Sidebar Menu
        #sidebar-menu {

            .menu-title,
            .menu-arrow,
            .label,
            .badge {
                display: none !important;
            }

            >ul {
                >li {
                    position: relative;
                    white-space: nowrap;

                    >a {
                        padding: 15px 20px;
                        min-height: 54px;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $sidemenu-item-hover-color;
                        }

                        i {
                            font-size: 1.125rem;
                            margin-right: 20px;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                        }
                    }

                    &:hover {
                        >a {
                            position: relative;
                            width: calc(190px + #{$leftbar-width-collapsed});
                            color: $sidemenu-item-active-color;
                            background-color: darken($bg-leftbar-light, 2%);
                            transition: none;

                            span {
                                display: inline;
                            }
                        }

                        a.open,
                        a.mm-active {
                            :after {
                                display: none;
                            }
                        }

                        >ul {
                            display: block;
                            left: $leftbar-width-collapsed;
                            position: absolute;
                            width: 190px;
                            height: auto !important;
                            box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, .2);

                            ul {
                                box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, .2);
                            }

                            a {
                                box-shadow: none;
                                padding: 8px 20px;
                                position: relative;
                                width: 190px;
                                z-index: 6;

                                &:hover {
                                    color: $sidemenu-item-hover-color;
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $bg-leftbar-light;

                    li {
                        &:hover {
                            >ul {
                                display: block;
                                left: 190px;
                                margin-top: -36px;
                                position: absolute;
                                width: 190px;
                            }
                        }

                        >a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }

                    li.active {
                        a {
                            color: $sidemenu-item-active-color;
                        }
                    }
                }
            }
        }
    }

    // Content Page
    .content-page {
        margin-left: $leftbar-width-collapsed  !important;
    }

    //Footer
    .footer {
        left: $leftbar-width-collapsed  !important;
    }

    //User box
    .user-box {
        display: none;
    }
}

// Body min-height set
body.enlarged {
    min-height: 1200px;
}

@include media-breakpoint-down(sm) {
    body {
        overflow-x: hidden;
        padding-bottom: 80px;
    }

    .left-side-menu {
        display: none;
        z-index: 10 !important;
    }

    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
    }

    .content-page,
    .enlarged .content-page {
        margin-left: 0 !important;
        padding: 0 10px;
    }

    .pro-user-name {
        display: none;
    }

    .logo-box {
        display: none;
    }
}

/* =============
Small Menu
============= */

body[data-sidebar-size="small"] {
    .logo-box {
        width: $leftbar-width-sm;
    }

    .left-side-menu {
        width: $leftbar-width-sm;
        text-align: center;

        #sidebar-menu {
            >ul {

                >li {
                    >a {
                        >i {
                            display: block;
                            font-size: 18px;
                            line-height: 24px;
                            width: 100%;
                            margin: 0;
                        }
                    }
                }

                ul {
                    padding-left: 0;

                    a {
                        padding: 10px 20px;
                    }
                }
            }
        }

        .menu-arrow,
        .badge {
            display: none !important;
        }

        &+.content-page {
            margin-left: $leftbar-width-sm;
        }

        +.content-page .footer {
            left: $leftbar-width-sm;
        }

        .menu-title {
            background-color: darken($bg-leftbar-light, 4%);
        }
    }

    &.enlarged {
        #wrapper {
            .left-side-menu {
                text-align: left;

                ul {
                    li {
                        a {
                            i {
                                display: inline-block;
                                font-size: 18px;
                                line-height: 17px;
                                margin-left: 3px;
                                margin-right: 15px;
                                vertical-align: middle;
                                width: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

}



// Leftbar-dark
body[data-sidebar="dark"] {

    .logo-box {
        background-color: $bg-leftbar-dark;
    }

    .logo {
        &.logo-dark {
            display: none;
        }

        &.logo-light {
            display: block;
        }
    }

    .user-box {
        .user-name {
            color: #f7f7f7;
        }
    }

    .user-box {
        .img-thumbnail {
            background-color: $thumbnail-bg;
            border-color: $thumbnail-border-color;
        }
    }

    .left-side-menu {
        background-color: $bg-leftbar-dark;
        box-shadow: none;

        .text-dark {
            color: $gray-300  !important;
        }

        #sidebar-menu {
            >ul {
                >li {
                    >a {
                        color: $sidemenu-item-color-dark;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $sidemenu-item-hover-color-dark;
                        }
                    }

                    >a.active {
                        color: $sidemenu-item-active-color-dark;
                        background-color: lighten($bg-leftbar-dark, 2.5%);
                        border-right-color: $sidemenu-item-active-color-dark;
                    }
                }
            }

            .menu-title {
                color: $gray-500;
            }
        }

        .nav-second-level,
        .nav-thrid-level {
            li {
                a {
                    color: $sidemenu-item-color-dark;

                    &:focus,
                    &:hover {
                        background-color: transparent;
                        color: $sidemenu-item-hover-color-dark;
                    }
                }
            }
        }

        .nav-second-level,
        .nav-third-level {
            li.mm-active {
                >a {
                    color: $sidemenu-item-active-color-dark;
                }
            }
        }
    }

    &.enlarged {
        #wrapper {
            .left-side-menu {
                #sidebar-menu {
                    >ul {
                        >li {
                            &:hover>a {
                                background-color: lighten($bg-leftbar-dark, 2.5%);
                                color: $sidemenu-item-active-color-dark;
                            }
                        }
                    }
                }

                .nav-second-level,
                .nav-third-level {
                    li.mm-active {
                        >a {
                            color: $sidemenu-item-active-color;
                        }
                    }
                }
            }
        }
    }
}

// Leftbar with user

.user-box {
    .user-name {
        color: #343a40;
    }
}

.user-pro-dropdown {
    background-color: $gray-100;
    box-shadow: none;
    padding: 15px 5px;
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;

    .dropdown-item {
        border-radius: 3px;

        i {
            display: inline-block;
        }

        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}