/*
Template Name: Adminto - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 4.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";

//Components
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/ribbons";
@import "custom/components/print";
@import "custom/components/preloader";
@import "custom/components/accordions";

// pages
@import "custom/pages/components-demo";
@import "custom/pages/email";
@import "custom/pages/authentication";
@import "custom/pages/error";
@import "custom/pages/taskboard";
@import "custom/pages/taskdetails";
@import "custom/pages/profile";
@import "custom/pages/pricing";
@import "custom/pages/timeline";
@import "custom/pages/gallery";
@import "custom/pages/coming-soon";

// Vendors
@import "vendor/metisMenu";

// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/slimscroll";
@import "custom/plugins/sweetalert";
@import "custom/plugins/toastr";
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/bootstrap-tagsinput";
@import "custom/plugins/multiple-select";
@import "custom/plugins/select2";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/colorpicker";
@import "custom/plugins/daterange";
@import "custom/plugins/parsley";
@import "custom/plugins/form-wizard";
@import "custom/plugins/dropify";
@import "custom/plugins/form-editor";
@import "custom/plugins/x-editable";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/tablesaw";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartist";
@import "custom/plugins/chartjs";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/calendar";
@import "custom/plugins/summernote";
@import "custom/plugins/hopscotch";
@import "custom/plugins/jstree";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";




.MTableHeader-header-13{
  position: inherit !important;
}


.react-calendar-heatmap  .color-empty{
  fill: #eeeeee
}
.react-calendar-heatmap .color-scale-0 { fill: #eeeeee; }
.react-calendar-heatmap .color-scale-1 { fill: #00ffa9; }
.react-calendar-heatmap .color-scale-2 { fill: #00f2a1; }
.react-calendar-heatmap .color-scale-3 { fill: #02e398; }
.react-calendar-heatmap .color-scale-4 { fill: #00d18b; }
.react-calendar-heatmap .color-scale-5 { fill: #00cf89; }
.react-calendar-heatmap .color-scale-6 { fill: #02bf7f; }
.react-calendar-heatmap .color-scale-7 { fill: #02b075; }
.react-calendar-heatmap .color-scale-8 { fill: #009e68; }
.react-calendar-heatmap .color-scale-9 { fill: #009663; }
.react-calendar-heatmap .color-scale-10 { fill: #017a51; }

.react-calendar-heatmap .color-github-resgate-0 { fill: #eeeeee; }
.react-calendar-heatmap .color-github-resgate-1 { fill: #fa0239; }
.react-calendar-heatmap .color-github-resgate-2 { fill: #ed0035; }
.react-calendar-heatmap .color-github-resgate-3 { fill: #e00234; }
.react-calendar-heatmap .color-github-resgate-4 { fill: #d10231; }
.react-calendar-heatmap .color-github-resgate-5 { fill: #c9022f; }
.react-calendar-heatmap .color-github-resgate-6 { fill: #ba022c; }
.react-calendar-heatmap .color-github-resgate-7 { fill: #a80228; }
.react-calendar-heatmap .color-github-resgate-8 { fill: #9c0225; }
.react-calendar-heatmap .color-github-resgate-9 { fill: #8c0322; }
.react-calendar-heatmap .color-github-resgate-10 { fill: #75021c ; }

.react-calendar-heatmap {
  width: 100%;
  height: 100%;
}
